import { createRouter, createWebHistory } from "vue-router";
import fullYearFoodMenu from "../components/fullYearFoodMenu.vue";
import AboutUs from "../components/AboutUs/AboutUsComponent.vue";

const routes = [
  { path: "/menu", name: "fullYearFoodMenu", component: fullYearFoodMenu }, // Main entry point
  { path: "/", redirect: "/menu" }, // Redirect from root to /home
  {
    path: "/about",
    name: "AboutUs",
    component: AboutUs,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
